import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { wayfinderBackendApiRef, WayfinderBackendClient } from './api';

export const wayfinderPlugin = createPlugin({
  id: 'wayfinder',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: wayfinderBackendApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ discoveryApi, fetchApi, identityApi }) =>
        new WayfinderBackendClient({ discoveryApi, fetchApi, identityApi }),
    }),
  ],
});

export const WayfinderUserWorkspacesPage = wayfinderPlugin.provide(
  createRoutableExtension({
    name: 'WayfinderUserWorkspacesPage',
    component: () =>
      import('./components/UserWorkspacesComponent').then(m => m.UserWorkspacesComponent),
    mountPoint: rootRouteRef,
  }),
);

export const WayfinderAppViewPage = wayfinderPlugin.provide(
  createRoutableExtension({
    name: 'WayfinderAppViewPage',
    component: () =>
      import('./components/AppViewComponent').then(m => m.AppViewComponent),
    mountPoint: rootRouteRef,
  }),
);
