import {
    bitbucketAuthApiRef,
    githubAuthApiRef,
    googleAuthApiRef,
    microsoftAuthApiRef,
    useApi,
} from '@backstage/core-plugin-api';
import { useCallback, useState } from 'react';
import { useAsync } from 'react-use';
import { OAuthConfig } from '../../types';

const oauthApiRefMap = {
    microsoft: microsoftAuthApiRef,
    github: githubAuthApiRef,
    google: googleAuthApiRef,
    bitbucket: bitbucketAuthApiRef,
};

export const useOauthSignIn = ({ provider, scopes }: OAuthConfig) => {
    const authApi = useApi(oauthApiRefMap[provider ?? 'github']);
    const [isSignedIn, setSignedIn] = useState(false);
    const [token, setToken] = useState('');
    const [forceLogin, setForceLogin] = useState(false);

    const doSignIn = useCallback(
        async (usePopup = true) => {
            const accessToken = await authApi.getAccessToken(scopes, {
                optional: !usePopup,
                instantPopup: usePopup,
            });
            if (accessToken) {
                setSignedIn(!!accessToken);
                setToken(accessToken);
            }
        },
        [authApi, setSignedIn, scopes],
    );

    const { loading, error } = useAsync(async () => {
        if (!isSignedIn) {
            await doSignIn(forceLogin);
        }
    }, [doSignIn, forceLogin, isSignedIn]);

    const showSignInModal = useCallback(() => {
        setForceLogin(true);
    }, [setForceLogin]);

    return { token, loading, error, isSignedIn, showSignInModal };
};
