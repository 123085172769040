import {
  AppComponent,
  AppEnv,
  Cluster,
  Stage,
  Workspace,
} from "@internal/backstage-plugin-wayfinder-common";
import {
  DiscoveryApi,
  IdentityApi,
  FetchApi,
  ProfileInfo
} from "@backstage/core-plugin-api";
import { CatalogClient } from '@backstage/catalog-client';

export class WayfinderBackendClient {

  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;
  private readonly fetchApi: FetchApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
    this.fetchApi = options.fetchApi;
  }

  async getUserWorkspaces(): Promise<Workspace[]> {
    return await this.callBackend('workspaces');
  }

  async getAppComponents(workspaceKey: string, appName: string): Promise<AppComponent[]> {
    return await this.callBackend(`${workspaceKey}/${appName}/components`);
  }

  async getWorkspaceClusters(workspaceKey: string): Promise<Cluster[]> {
    return await this.callBackend(`${workspaceKey}/clusters`);
  }

  async getStages(): Promise<Stage[]> {
    return await this.callBackend('stages');
  }

  async getAppEnvs(workspaceKey: string, appName: string): Promise<AppEnv[]> {
    return await this.callBackend(`${workspaceKey}/${appName}/envs`);
  }

  // private

  private async callBackend(endpoint: string): Promise<any> {

    const [url, { token }] = await Promise.all([
      `${await this.discoveryApi.getBaseUrl('wayfinder')}/${endpoint}`,
      await this.identityApi.getCredentials()
    ]);

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    });

    return await this.handleResponse(response);
  }

  private async handleResponse(response: Response): Promise<any> {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return await response.json();
  }

  // helpers 

  // getCurrentUserProfile gets the current user profile
  // wayfinder-backend can extract identity information if needed so this might not be necessary
  private async getCurrentUserProfile(): Promise<ProfileInfo> {
    const { token } = await this.identityApi.getCredentials();
    const { userEntityRef } = await this.identityApi.getBackstageIdentity();
    const profile = await this.identityApi.getProfileInfo();

    // if for some reason, the email is not available in the profile try to get it from the catalog
    // @todo(mc): this could be caused by the IDP resolver behaviour when email is not exposed by the auth provider?
    if (profile.email === undefined) {
      const catalogClient = new CatalogClient({ discoveryApi: this.discoveryApi, fetchApi: this.fetchApi });
      const user = await catalogClient.getEntityByRef(userEntityRef, { token });
      if (user?.spec?.profile?.email !== undefined) {
        profile.email = user.spec.profile.email;
      }
    }

    return profile;
  }
}
